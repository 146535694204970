<template>
  <div>
    <!-- popup подтверждающий удаление -->
    <DeletePopup
      title="из детской группы"
      :name="nameUser"
      :dialog="dialog"
      @closeDeletePopup="closeDeletePopup"
      :delete-function="deleteUser"
    />
    <!-- /popup подтверждающий удаление-->

    <TableFilters :title-count="sourceObject.count" :queries="[]">
      <template v-slot:download-button>
        <v-btn
          @click="downloadList"
          class="btn btn_color_grey btn-unload flex-right mr-4"
          :disabled="loadingBtn || sourceObject.results.length == 0"
          :loading="loadingBtn"
        >
          Выгрузить
        </v-btn>
      </template>
      <template v-slot:add-button>
        <router-link
          to="manageressCreate"
          append
          class="btn btn-add btn_color_green mr-5"
          >Добавить воспитателя</router-link
        >
      </template>
    </TableFilters>

    <TableLayout
      disable-router
      :loading="isDateLoaded"
      v-if="sourceObject.results"
      :header="header"
      to="personnel"
      :source-object="sourceObject"
      :table-data="sourceObject.results"
      delete-button-direction-start
    >
      <template slot-scope="{ itemFather }">
        <button
          @click="clickDelete(itemFather)"
          class="form__btn-delete btn btn-delete"
          style="background-color: #eb5c6d"
        ></button>
      </template>
    </TableLayout>
  </div>
</template>

<script>
import TableLayout from "@/components/tables/TableLayout";
import TableFilters from "@/components/tables/TableFilters";
import DeletePopup from "@/components/popup/DeletePopupCommon";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
import phoneFilter from "@/helpers/filters/phoneFilter";

export default {
  name: "Manageress",
  components: { TableFilters, TableLayout, DeletePopup },
  data() {
    return {
      dialog: false, // Диалоговое окно popup удаления
      nameUser: "", // Имя пользователя
      objUser: {},
      loadingBtn: false,

      header: [
        {
          text: "Пользователь",
          value: `last_name`,
          renderFunction: value => `${value.additional_fields[0].value}` || "-",
          sort: true
        },

        {
          text: "Телефон",
          renderFunction: value => phoneFilter.execute(value?.phone_number),
          sort: true
        }
      ]
    };
  },
  computed: {
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    sourceObject() {
      return this.$store.getters.getManageressList;
    }
  },
  methods: {
    downloadList() {
      this.loadingBtn = true;
      this.$store
        .dispatch("downloadKindergartensManageressList", this.header)
        .then(() => {
          this.loadingBtn = false;
        });
    },
    deleteUser() {
      // let self = this;
      let id = this.objUser.id;

      this.isDateLoaded = true;
      this.$store
        .dispatch("deleteManageressFromGroup", {
          id,
          childrenGroupsId: this.$store.state.searchQuery.pageQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.manager)
          );
        })
        .finally(() => {
          this.$store.dispatch("fetchManageressById").finally(() => {
            this.isDateLoaded = false;
          });
        });
    },

    clickDelete(obj) {
      this.objUser = obj;
      this.nameUser =
        obj.last_name + " " + obj?.first_name + " " + obj?.middle_name;
      this.dialog = true;
    },

    closeDeletePopup(data) {
      this.dialog = data;
    }
  },
  created() {
    this.$store.dispatch("clearSortQuery");
    this.$store.commit("setSearchQuery", {
      query: { ...this.urlQuery.query, children_groups: this.urlQuery.id },
      id: this.urlQuery.id
    });
    this.isDateLoaded = true;
    this.$store.dispatch("fetchManageressById").finally(() => {
      this.isDateLoaded = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path === from.path) {
        this.$store.commit("setSearchQuery", {
          id: this.urlQuery.id,
          query: {
            ...to.query,
            children_groups: this.urlQuery.id
            // type: this.type
          }
        });
        this.isDateLoaded = true;
        this.$store.dispatch("fetchManageressById").finally(() => {
          this.isDateLoaded = false;
        });
      }
    }
  }
};
</script>

<style scoped></style>
