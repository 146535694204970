<template>
  <div>
    <MainLayout>
      <template v-if="sourceObject" v-slot:navbar-title>{{
        sourceObject ? sourceObject.name : ""
      }}</template>
      <template
        v-slot:navbar-btn-wrap
        v-if="
          this.$store.getters.getCurrentRole
            ? this.$store.getters.getCurrentRole !== 21
            : ''
        "
      >
        <DeleteAgreementPopup
          name="детскую группу"
          :title="sourceObject ? sourceObject.name : ''"
          :delete-function="deleteChildrenGroup"
          :description="description"
          agreeDescription="Аннулировать платные доступы"
          agreeSubDescription="Доступы для опекунов, которые связаны с воспитанниками из других детских групп, будут перенесены без удаления."
        />
        <router-link
          append
          :to="`/childrenGroupEdit/` + urlQuery.id"
          class="btn btn-edit btn_color_grey"
          >редактировать</router-link
        >
      </template>
      <SlideLayout :tabs="slideTabs.filter(el => el.role !== 21)">
        <template
          v-slot:info
          v-if="
            this.$store.getters.getCurrentRole
              ? this.$store.getters.getCurrentRole !== 21
              : ''
          "
        >
          <div class="keep__title">
            <p class="keep__text">Общая информация</p>

          </div>
          <BasicTableWithoutHeader :table-data="getDataRequisites">
            <template #item.3.value="{item}">
              <div style="min-width: 250px"> Приложение:
                {{ item?.value?.appprices_details?.[0]?.duration }} мес.={{ item?.value?.appprices_details?.[0]?.cost_with_discount }}₽ |
                {{ item?.value?.appprices_details?.[1]?.duration }} мес.={{ item?.value?.appprices_details?.[1]?.cost_with_discount }}₽
                <br>
                Карта:
                {{ item?.value?.cardprices_details?.[0]?.duration}} мес. = {{item?.value?.cardprices_details?.[0]?.price }}₽
              </div>
            </template>
            <template #item.4.value="{item}">
              <div class="" v-for = "oneItem in item.value" :key="oneItem.id">
                {{ oneItem.name }}
              </div>
            </template>
          </BasicTableWithoutHeader>
        </template>
        <template
          v-slot:Manageress
          v-if="
            this.$store.getters.getCurrentRole
              ? this.$store.getters.getCurrentRole !== 21
              : ''
          "
        >
          <Manageress />
        </template>
        <template v-slot:Children>
          <Children />
        </template>
      </SlideLayout>
    </MainLayout>
  </div>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import SlideLayout from "@/components/MoreInfo/SlideLayout";
import BasicTableWithoutHeader from "@/components/tables/BasicTableWithoutHeader";
import Manageress from "@/components/info/ChildrenGroup/Manageress";
import Children from "@/components/info/ChildrenGroup/Children";
import DeleteAgreementPopup from "@/components/popup/DeleteAgreementPopup.vue";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
export default {
  name: "ChildrenGroupsInfosPages",
  components: {
    DeleteAgreementPopup,
    BasicTableWithoutHeader,
    SlideLayout,
    MainLayout,
    Manageress,
    Children
  },
  computed: {
    description() {
      return `Детская группа "${this.sourceObject.name}" будет удалена безвозвратно. Вы действительно хотите продолжить?`;
    },
    slideTabs() {
      return [
        {
          text: "Воспитанники",
          value: "Children",
          icon: null,
          count: this.sourceObject?.children_count || "-"
        },
        {
          text: "Общая информация",
          value: "info",
          icon: "i",
          role: this.$store.getters.getCurrentRole
        },
        {
          text: "Воспитатели",
          value: "Manageress",
          icon: null,
          count: this.$store.getters.getChildrenGroupsInfo
            ? this.$store.getters.getChildrenGroupsInfo.teachers_count
            : 0,
          role: this.$store.getters.getCurrentRole
        }
      ];
    },
    urlQuery() {
      return {
        query: this.$route.query,
        id: this.$route.params.id
      };
    },
    sourceObject() {
      return this.$store.getters.getChildrenGroupsInfo;
    },
    sourceGroupObject() {
      return this.$store.getters.getOrganizationGroupList.results;
    },
    tableData() {
      let childrenGroups = this.sourceObject
      let usersGroups = this.sourceGroupObject

      let result = this.mergeObjects(childrenGroups,usersGroups)
      return result[0]
    },
    getDataRequisites() {
      return [
        {
          text: "Номер детской группы",
          value: this?.tableData?.number || "-"
        },
        {
          text: "Название группы",
          value: this?.tableData?.name || "-"
        },

        {
          text: "Группа пользователей",
          value: this?.tableData?.group_details?.title || "-"
        },
        {
          text: "Стоимость",
          value: this?.tableData || "-"
        },
        {
          text: "Контроллеры",
          value: this?.tableData?.controllers_details || "-"
        },


      ];
    }
  },
  created() {

    this.$store.commit("setSearchQuery", {
      query: this.urlQuery.query,
      id: this.urlQuery.id
    });
    this.isDateLoaded = true;

    this.$store.dispatch("fetchChildrenGroupInfo").finally(() => {
      this.isDateLoaded = false;
    });

    this.$store.dispatch("fetchOrganizationUserGroupInfoList").finally(() => {
      this.isDateLoaded = false;
    });
  },
  methods: {
    // mergeObjects(obj1, obj2) {
    //   const merged = [];
    //   obj1.results.forEach((item1) => {
    //     obj2.results.forEach((item2) => {
    //       if (item1.group === item2.id) {
    //         console.log(item2.id)
    //         delete item2.id
    //         const mergedItem = {...item1, ...item2};
    //         merged.push(mergedItem);
    //
    //
    //       }
    //     });
    //   });
    //
    //   return merged;
    // },
    mergeObjects(obj1, obj2) {
      const merged = [];

      obj2.forEach(item => {
        if (item.id === obj1.group) {
                  delete item.id
                  const mergedItem = {...item, ...obj1};
                  merged.push(mergedItem);
        }
      });
          return merged;
    },
    deleteChildrenGroup() {
      // if (this.$store.getters.getCurrentRole == 31) {
      //   this.$store.dispatch("deleteUserGroup", {
      //     id: this.$store.getters.getChildrenGroupsInfo.group
      //   });
      // }

      this.$store
        .dispatch("deleteChildrenGroup", {
          id: this.urlQuery.id
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.remove(successMessageDictionary.childrenGroup)
          );
        });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch("clearOrganizationPage").then(() => next());
  }
};
</script>

<style scoped></style>
