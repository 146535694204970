var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('MainLayout',{scopedSlots:_vm._u([(_vm.sourceObject)?{key:"navbar-title",fn:function(){return [_vm._v(_vm._s(_vm.sourceObject ? _vm.sourceObject.name : ""))]},proxy:true}:null,(
        this.$store.getters.getCurrentRole
          ? this.$store.getters.getCurrentRole !== 21
          : ''
      )?{key:"navbar-btn-wrap",fn:function(){return [_c('DeleteAgreementPopup',{attrs:{"name":"детскую группу","title":_vm.sourceObject ? _vm.sourceObject.name : '',"delete-function":_vm.deleteChildrenGroup,"description":_vm.description,"agreeDescription":"Аннулировать платные доступы","agreeSubDescription":"Доступы для опекунов, которые связаны с воспитанниками из других детских групп, будут перенесены без удаления."}}),_c('router-link',{staticClass:"btn btn-edit btn_color_grey",attrs:{"append":"","to":`/childrenGroupEdit/` + _vm.urlQuery.id}},[_vm._v("редактировать")])]},proxy:true}:null],null,true)},[_c('SlideLayout',{attrs:{"tabs":_vm.slideTabs.filter(el => el.role !== 21)},scopedSlots:_vm._u([(
          this.$store.getters.getCurrentRole
            ? this.$store.getters.getCurrentRole !== 21
            : ''
        )?{key:"info",fn:function(){return [_c('div',{staticClass:"keep__title"},[_c('p',{staticClass:"keep__text"},[_vm._v("Общая информация")])]),_c('BasicTableWithoutHeader',{attrs:{"table-data":_vm.getDataRequisites},scopedSlots:_vm._u([{key:"item.3.value",fn:function({item}){return [_c('div',{staticStyle:{"min-width":"250px"}},[_vm._v(" Приложение: "+_vm._s(item?.value?.appprices_details?.[0]?.duration)+" мес.="+_vm._s(item?.value?.appprices_details?.[0]?.cost_with_discount)+"₽ | "+_vm._s(item?.value?.appprices_details?.[1]?.duration)+" мес.="+_vm._s(item?.value?.appprices_details?.[1]?.cost_with_discount)+"₽ "),_c('br'),_vm._v(" Карта: "+_vm._s(item?.value?.cardprices_details?.[0]?.duration)+" мес. = "+_vm._s(item?.value?.cardprices_details?.[0]?.price)+"₽ ")])]}},{key:"item.4.value",fn:function({item}){return _vm._l((item.value),function(oneItem){return _c('div',{key:oneItem.id},[_vm._v(" "+_vm._s(oneItem.name)+" ")])})}}],null,false,3040701921)})]},proxy:true}:null,(
          this.$store.getters.getCurrentRole
            ? this.$store.getters.getCurrentRole !== 21
            : ''
        )?{key:"Manageress",fn:function(){return [_c('Manageress')]},proxy:true}:null,{key:"Children",fn:function(){return [_c('Children')]},proxy:true}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }